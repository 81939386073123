<template>
    <div class="casePoster">
        <div class="wrap">
            <div class="canvas" id="posterCanvas">
                <poster @loadedImage="loadedImage" :detailObj="detailObj"></poster>
            </div>
        </div>
        <div class="posterImg">
            <img :src="posterImg" alt="">
            <div class="save"><i class="iconfont icon-fenxiangbeifen"></i>长按图片保存至相册并分享</div>
        </div>
        <div class="shareBtn">
            <div class="wechat">
                <i class="iconfont icon-wechat"></i>
                分享到微信
            </div>
            <div class="wechat copy" :data-clipboard-text="url" @click="copyWord">
                 <i class="iconfont icon-wechat"></i>
                复制链接
            </div>
        </div>
        <div class="next" @click="cancle">取消</div>
    </div>
</template>
<script>
import html2canvas from 'html2canvas'
import poster from '../components/poster'
export default {
    name:'casePoster',
    components:{
        poster

    },
    data(){
        return{
            posterImg:'',
            showPoster:true,
            url:'',
            detailObj:{}
        }
    },
    created(){
        // this.createPoster();

    },
    mounted(){
        let query = this.$route.query;
        if(query.appid){
            this.getDetail(query.appid);
        }
        
        this.url = window.location.href;
    },
    methods:{
         // 生成海报
    createPoster() {
      const vm = this;
      const domObj = document.getElementById("posterCanvas");
      html2canvas(domObj, {
        useCORS: false,
        allowTaint: false,
        logging: false,
        width: domObj.clientWidth, //dom 原始宽度
        height: domObj.clientHeight,
        scrollY: 0,
        scrollX: 0,
      }).then(function (canvas) {
        vm.posterImg = canvas.toDataURL("image/png");
        vm.showPoster = false; // 隐藏选择模板页
      });
    },
    loadedImage(){
        setTimeout(() => {
             this.createPoster();
        }, 100);
    },
    cancle(){
        this.$router.go(-1);
    },
     getDetail(app_id){
            let params = {
                app_id:app_id
            }
            this.$http.get('/user_service/case_library/get_case_details',{params})
            .then(res=>{
                console.log('res',res.data)
                if(res.code == 0){
                    this.detailObj = res.data;
                    this.share(res.data)
                }
            })
        },
    share(item){
        let url = window.location.href;
        let obj = {
            title:item.shop_name?item.shop_name:'案例标题',
            desc:'点击查看案例详情',
            link:url,
            imgUrl:item.shop_logo?item.shop_logo:this.defaultImg,
            success:function(){
                // alert('成功')
                
            }
        }
        // this.$EventBus.$emit('shareContent',true,obj);
        // this.$emit('share');
    },
    copyWord(){
    　　　  var clipboard = new this.Clipboard(".copy"); //在main.js中引用
            clipboard.on("success", e => {
                // 释放内存
                // this.$emit('cancle');
                setTimeout(() => {
                    this.$Toast('链接复制成功')
                    clipboard.destroy();
                }, 1);
                
            });
            clipboard.on("error", e => {
                // 不支持复制
                this.$Toast({
                message: "该浏览器不支持自动复制",
                type: "warning"
                });
                // 释放内存
                clipboard.destroy();
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.casePoster{
    background: #fff;
    .wrap{
        width:100%;
        position: absolute;
        top:0;
        left:0;
        opacity: 0;
        .canvas{
            margin:0 1.3rem;
        }
    }
    .posterImg{
        background: #f5f5f5;
        padding:.72rem 1.3rem 1.04rem;
        img{
            width:100%;
        }
        .save{
            font-size: .24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-top:.48rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            i{
                font-size:.32rem;
                margin-right:.08rem;
            }
        }
    }
    .shareBtn{
        width:100%;
        display: flex;
        .wechat{
            flex:1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .28rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            padding:.24rem 0;
            i{
                font-size:.48rem;
                margin-right:.08rem;

            }


        }
    }
    .next{
        margin:.16rem .32rem;
        height: .8rem;
        background: #105CFB;
        border-radius: .4rem;
        font-size: .28rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;

    }
    
}
    
</style>