<template>
    <div class="poster">
        <div class="posterCanvas">
            <img src="@/assets/case_poster.png" alt="" class="bg">
            <div class="info">
                <div class="avator">
                    <img :src="avator" alt="">
                    {{saleInfo.user_name}}
                </div>
                <div class="picture"><img :src="shop_logo" alt=""></div>
                <div class="desc">
                    {{detailObj.case_name?detailObj.case_name:detailObj.shop_name?detailObj.logo_name:''}}
                </div>
                <div class="qrcode">
                    <div id="qrcode" ref="qrcode"></div>
                    <div class="longPress">长按扫码查看课程</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {getToken} from '@/util/util';
import QRCode from 'qrcodejs2'
export default {
    name:'poster',
    props:{
        detailObj:{
            type:Object
        }
    },
    watch:{
        detailObj(val){
            if(val&&val.app_id){
                const that = this;
                var image1 = new Image();  
                image1.setAttribute('crossOrigin', 'anonymous');l
                image1.src=val.shop_logo;
                image1.onload = function(){  
                    that.shop_logo = that.getBase64Image(image1);
                    console.log('shop_logo',that.shop_logo);
                    that.$emit('loadedImage')
                }

                // var image2 = new Image();  
                // image2.setAttribute('crossOrigin', 'anonymous');
                // image2.src ='http://wechatapppro-1252524126.file.myqcloud.com/apprnDA0ZDw4581/image/b5efdaedb7879b01f325bb1664e00fc8.png';
                // image2.onload = function(){  
                //     that.avator = that.getBase64Image(image2);
                //     console.log('avator',that.avator)
                //     that.$emit('loadedImage')
                // }  
        }
        }

    },
    data(){
        return{
            saleInfo:{},
            shop_logo:'',
            avator:'',
            defaultImg:'http://wechatapppro-1252524126.file.myqcloud.com/apprnDA0ZDw4581/image/0398fe634cc41cd9fa619772271b7c11.png',
        }
    },
    mounted(){
        this.saleInfo = getToken('saleInfo');
        this.crateQrcode();
    },
    methods:{
         crateQrcode () {
             let query = window.location.href.split('?')[1];
             let url = `${process.env.VUE_APP_USESR_SERVER}/detail?${query}`
             console.log('url',url)
                setTimeout(() => {
                    new QRCode(this.$refs.qrcode, {
                    text: url,
                    width: 56,
                    height: 56,
                    colorDark: '#333333', // 二维码颜色
                    colorLight: '#ffffff', // 二维码背景色
                    correctLevel: QRCode.CorrectLevel.H // 容错率L/M/H
                    }, 100)
                })
            },
        getBase64Image(img) {  
            var canvas = document.createElement("canvas");  
            canvas.width = img.width;  
            canvas.height = img.height;  
            var ctx = canvas.getContext("2d");  
            ctx.drawImage(img, 0, 0, img.width, img.height);  
            var ext = img.src.substring(img.src.lastIndexOf(".")+1).toLowerCase();  
            var dataURL = canvas.toDataURL("image/"+ext);  
            return dataURL;  
        }  ,
    }
    
}
</script>
<style lang="scss" scoped>
.poster{
    // padding:.72rem 1.3rem;
     .posterCanvas{
        position: relative;
        .bg{
            width:100%;
            display: block;
        }
        .info{
            width:100%;
            height:100%;
            position: absolute;
            top:0;
            left:0;
            z-index: 3;
            .avator{
                color:#fff;
                height: .4rem;
                display: flex;
                align-items: center;
                padding:.4rem 0 0 .6rem;
                img{
                    width:.4rem;
                    height:.4rem;
                    margin-right:.1rem;
                    border-radius: 50%;
                }

            }
            .picture{
                width:100%;
                height:2.25rem;
                margin-top:1.3rem;
                // background: rgba(0,0,0,.5);
                text-align: center;
                img{
                    height:2.25rem;
                }
            }
            .desc{
                width:55%;
                margin-left:.24rem;
                font-size:.24rem;
                margin-top:1.4rem;
            }
            .qrcode{
                // margin:.4rem 0 0 .24rem;
                position: absolute;
                bottom:.32rem;
                left:.24rem;
                #qrcode{
                    width:56px;
                    height:56px;
                    margin-left:.2rem;
                }
                .longPress{
                    margin-top:.2rem;
                    font-size:.2rem;
                }
            }
        }
    }
}

    
</style>